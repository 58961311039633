import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import './ash-element.scss';

interface IProps {
  name: string;
  hideName?: boolean;
}

export const AshItem: React.FC<IProps> = ({ name, hideName }) => {
  return (
    <div className={`ash-item ${name}`}>
      {name === 'Corrosion' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/ele_corrosion.webp"
            alt={name}
          />
        </>
      )}
      {name === 'Fire' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/ele_fire.webp"
            alt={name}
          />
        </>
      )}
      {name === 'Ice' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/ele_ice.webp"
            alt={name}
          />
        </>
      )}
      {name === 'Lightning' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/ele_lightning.webp"
            alt={name}
          />
        </>
      )}
      {name === 'Physical' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/ele_physical.webp"
            alt={name}
          />
        </>
      )}
      {name === 'Water' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/ele_water.webp"
            alt={name}
          />
        </>
      )}
      {name === 'Wind' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/ele_wind.webp"
            alt={name}
          />
        </>
      )}
      {name === 'ATK' && (
        <>
          <StaticImage src="../../../images/ash/icons/stat_3.webp" alt={name} />
        </>
      )}
      {name === 'VIT' && (
        <>
          <StaticImage src="../../../images/ash/icons/stat_1.webp" alt={name} />
        </>
      )}
      {name === 'MST' && (
        <>
          <StaticImage src="../../../images/ash/icons/stat_4.webp" alt={name} />
        </>
      )}
      {name === 'DEF' && (
        <>
          <StaticImage src="../../../images/ash/icons/stat_2.webp" alt={name} />
        </>
      )}
      {name === 'TRM' && (
        <>
          <StaticImage src="../../../images/ash/icons/stat_5.webp" alt={name} />
        </>
      )}
      {name === 'Bulwark' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/class_bulwark.webp"
            alt={name}
          />
        </>
      )}
      {name === 'Ranger' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/class_ranger.webp"
            alt={name}
          />
        </>
      )}
      {name === 'Skirmisher' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/class_skirmisher.webp"
            alt={name}
          />
        </>
      )}
      {name === 'Striker' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/class_striker.webp"
            alt={name}
          />
        </>
      )}
      {name === 'Support' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/class_support.webp"
            alt={name}
          />
        </>
      )}
      {name === 'Tactician' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/class_tactician.webp"
            alt={name}
          />
        </>
      )}
      {name === 'Vanguard' && (
        <>
          <StaticImage
            src="../../../images/ash/icons/class_vanguard.webp"
            alt={name}
          />
        </>
      )}
      {!hideName && <strong className={name}>{name}</strong>}
    </div>
  );
};
