import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { AshItem } from '../../../modules/ash/components/ash-item';

const AshGuidesEle: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash-guide'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Elements & Reactions</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_elements.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Ash Echoes Elements & Reactions</h1>
          <h2>The Elements and Reactions systems in Ash Echoes explained.</h2>
          <p>
            Last updated: <strong>06/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Elements" />
        <p>
          There are seven elements in total: Fire, Lightning, Water, Ice, Wind,
          Physical and Corrosion. Out of these, only the last three do not
          participate in elemental reactions, having their own mechanics
          instead.
        </p>
        <ul>
          <li>
            <AshItem name="Fire" /> - Certain Echomancers can generate Fire
            Zones. In [Dry Environments], Fire DMG also has a chance to generate
            Fire Zones, and they last longer there than in [Wet Environment].
            Enemies inside a Fire Zone take Fire DMG equal to 1% of their
            current HP per second, with the damage capped at the value of the
            TRM stat.
          </li>
          <li>
            <AshItem name="Lightning" /> - Can trigger Elemental Reactions
            (Explosion, Electroconduct, Electrolyzed) but do not generate
            elemental zones.
          </li>
          <li>
            <AshItem name="Water" /> - Certain Echomancers can generate Water
            Zones. In [Wet Environments], Water Zones last longer and can even
            expand, while in [Dry Environments], they dry out faster. Enemies
            inside a Water Zone take 10% more Water DMG.
          </li>
          <li>
            <AshItem name="Ice" /> - Ice Zones can be generated by certain
            Echomancer skills or by dealing Ice DMG to enemies inside a Water
            Zone. Ice Zones are significantly easier to generate and keep in
            [Wet Environments]. Enemies inside an Ice Zone will be Frozen. Ice
            DMG on frozen enemies has a chance to trigger [Frostbreak], killing
            them instantly.
          </li>
          <li>
            <AshItem name="Wind" /> - Wind does not trigger elemental reactions,
            but it can blow away enemies, clear up poisonous mist, and deals
            additional damage to airborne enemies.
          </li>
          <li>
            <AshItem name="Physical" /> - Physical does not trigger elemental
            reactions, but can benefit from reaction bonuses, such as the Damage
            Reduction received from Vaporization or the extra damage dealt by
            Melt.
          </li>
          <li>
            <AshItem name="Corrosion" /> - Whenever one or more Corrosion
            Echomancers are on the field, a Corrosion Accumulation bar will
            appear on the top right of the screen. At 100 Corrosion
            Accumulation, a Corrosion Blast will occur, damaging all enemies in
            the map based on their current HP percentage. Corrosion scales with
            TRM and its bonus can be further enhanced once TRM exceeds 1000
            points.
          </li>
        </ul>
        <SectionHeader title="Reactions" />
        <ul>
          <li>
            <strong>[Vaporized]</strong>: <AshItem name="Fire" /> +{' '}
            <AshItem name="Water" />. Generates a Vaporized zone. Allied
            Echomancers take 50% less damage while inside this zone.
          </li>
          <li>
            <strong>[Explosion]</strong>: <AshItem name="Fire" /> +{' '}
            <AshItem name="Lightning" />. When a target inside a Fire Zone takes
            Lightning DMG, it receives additional DMG based on the ATK and MST
            of the unit who triggered it.
          </li>
          <li>
            <strong>[Melt]</strong>: <AshItem name="Fire" /> +{' '}
            <AshItem name="Ice" />. When a target inside a Fire Zone takes Ice
            DMG, or when a target inside an Ice Zone takes Fire DMG, it triggers
            [Melt]. Each instance of Melt reduces the target’s Basic Damage
            Reduction by 5%, up to 60%.
          </li>
          <li>
            <strong>[Electroconduct]</strong>: <AshItem name="Lightning" /> +{' '}
            <AshItem name="Water" />. When a target inside a Water Zone takes
            Lightning DMG, it triggers [Electroconduct]. Enemies affected by
            [Paralyzed] are unable to take actions. While auto-attacks will only
            Paralyze the main target, Lightning skills will make the effect
            spread and affect all enemies within the Water Zone.
          </li>
          <li>
            <strong>[Electrolyzed]</strong>: <AshItem name="Lightning" /> +{' '}
            <AshItem name="Ice" />. When a target inside an Ice Zone takes
            Lightning DMG, it triggers [Electrolyzed]. This effect reduces the
            ATK of the enemies within the zone by 33%, stacking up to 3 times
            (less effective against Elite and Boss enemies).
          </li>
          <li>
            <strong>[Frozen]</strong>: <AshItem name="Water" /> +{' '}
            <AshItem name="Ice" />. When a target inside a Water Zone takes Ice
            DMG, or when a target inside an Ice Zone takes Water DMG, it
            triggers [Freeze]. Frozen enemies cannot act.
          </li>
        </ul>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AshGuidesEle;

export const Head: React.FC = () => (
  <Seo
    title="Elements & Reactions | Ash Echoes | Prydwen Institute"
    description="The Elements and Reactions systems in Ash Echoes explained."
    game="ash"
  />
);
